const images = {
    header: {
        logo: require('../../assets/media/images/nav_logo.png'),
        youtube:  require('../../assets/media/images/youtube.svg').default
    },
    footer: {
        youtube: require('../../assets/media/images/youtube-logo-colored.svg').default
    },
    body: {
        bell: require('../../assets/media/images/bell.svg').default,
        playBtn: require('../../assets/media/images/play button.svg').default,
        latestVideo: require('../../assets/media/images/latest video.svg').default,
        favicon: require('../../assets/media/images/favicon.svg').default,
        channel: require('../../assets/media/images/home-video-cover.svg').default,
        bgBoxes: require('../../assets/media/images/bg-boxes.svg').default,
        orgDiamond: require('../../assets/media/images/org-dimond.svg').default,
        flameImg: require('../../assets/media/images/flame-img.svg').default,
        m_icon1: require('../../assets/media/images/m_icon1.svg').default,
        m_icon2: require('../../assets/media/images/m_icon2.svg').default,
        m_icon3: require('../../assets/media/images/m_icon3.svg').default,
        contact_img: require('../../assets/media/images/contact-img.svg').default,
        about_img: require('../../assets/media/images/about-image1.svg').default,
        stats_img1: require('../../assets/media/images/stats-img1.svg').default,
        stats_img2: require('../../assets/media/images/stats_img2.svg').default,
        stats_img3: require('../../assets/media/images/stats_img3.svg').default,
        stats_img4: require('../../assets/media/images/stats_img4.svg').default,
        stats_sign_m: require('../../assets/media/images/stats_sign_m.svg').default,
        stats_sign_p: require('../../assets/media/images/stats_sign_p.svg').default,
    },
    slider1: {
        slide1:  require('../../assets/media/slides/slider3/slide4.png'),
        slide2:  require('../../assets/media/slides/slider3/slide3.png'),
        slide3:  require('../../assets/media/slides/slider3/slide6.png'),
        slide4:  require('../../assets/media/slides/slider3/slide2.png'),
        slide5:  require('../../assets/media/slides/slider3/slide5.png'),
        slide6:  require('../../assets/media/slides/slider3/slide1.png'),
        slide7:  require('../../assets/media/slides/slider3/slide11.png'),
    },
    slider2: {
        slide1:  require('../../assets/media/slides/slider2/slide1.svg').default,
    },
    slider3: {
        slide1:  require('../../assets/media/slides/slider3/slide1.png'),
        slide2:  require('../../assets/media/slides/slider3/slide2.png'),
        slide3:  require('../../assets/media/slides/slider3/slide3.png'),
        slide4:  require('../../assets/media/slides/slider3/slide4.png'),
        slide5:  require('../../assets/media/slides/slider3/slide5.png'),
        slide6:  require('../../assets/media/slides/slider3/slide6.png'),
        slide7:  require('../../assets/media/slides/slider3/slide7.svg').default,
        slide8:  require('../../assets/media/slides/slider3/slide8.png'),
        slide9:  require('../../assets/media/slides/slider3/slide9.png'),
        slide10:  require('../../assets/media/slides/slider3/slide10.png'),
        slide11:  require('../../assets/media/slides/slider3/slide11.png'),
    },
    news: {
        news1: require('../../assets/media/news/news1.svg').default,
        news2: require('../../assets/media/news/news2.svg').default,
        news3: require('../../assets/media/news/news3.svg').default,
    },
    fShorts: {
        slide1:  require('../../assets/media/fShorts/slide1.png'),
        slide2:  require('../../assets/media/fShorts/slide2.png'),
        slide3:  require('../../assets/media/fShorts/slide3.png'),
        slide4:  require('../../assets/media/fShorts/slide4.png'),
        slide5:  require('../../assets/media/fShorts/slide5.png'),
        slide6:  require('../../assets/media/fShorts/slide6.png'),
        slide7:  require('../../assets/media/fShorts/slide7.png'),
        slide8:  require('../../assets/media/fShorts/slide8.png'),
        slide9:  require('../../assets/media/fShorts/slide9.png'),
        slide10:  require('../../assets/media/fShorts/slide10.png'),
        slide11:  require('../../assets/media/fShorts/slide11.png'),
        slide12:  require('../../assets/media/fShorts/slide12.png'),
        slide13:  require('../../assets/media/fShorts/slide13.png'),
        slide14:  require('../../assets/media/fShorts/slide14.png'),
        slide15:  require('../../assets/media/fShorts/slide15.png'),
        slide16:  require('../../assets/media/fShorts/slide16.png'),
        slide17:  require('../../assets/media/fShorts/slide17.png'),
        slide18:  require('../../assets/media/fShorts/slide18.png'),
        slide19:  require('../../assets/media/fShorts/slide19.png'),
        slide20:  require('../../assets/media/fShorts/slide20.png'),
        slide21:  require('../../assets/media/fShorts/slide21.png'),
        slide22:  require('../../assets/media/fShorts/slide22.png'),
        slide23:  require('../../assets/media/fShorts/slide23.png'),
        slide24:  require('../../assets/media/fShorts/slide24.png'),
        slide25:  require('../../assets/media/fShorts/slide25.png'),
        slide26:  require('../../assets/media/fShorts/slide26.png'),
        slide27:  require('../../assets/media/fShorts/slide27.png'),
        slide28:  require('../../assets/media/fShorts/slide28.png'),
        slide29:  require('../../assets/media/fShorts/slide29.png'),
        slide30:  require('../../assets/media/fShorts/slide30.png'),
    }
}

export default images